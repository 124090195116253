<template>
  <div>
    <!-- Table Container Card -->
    <b-card>
      <div class="m-2">
        <validation-observer ref="createTag" #default="{ invalid }">
          <b-row class="filter-row" style="padding: 0 10px">
            <b-col cols="12" md="6" class="px-0 pb-1">
              <h4 style="font-size: 18px; font-weight: 600">ساخت برچسب</h4>
            </b-col>
            <b-col class="px-0 d-none d-md-block" dir="ltr" cols="12" md="6">
              <b-button class="d-flex justify-content-center align-items-center" variant="primary" type="submit"
                :disabled="invalid || isLoading" @click="createTag">
                <span class="text-nowrap">ثبت برچسب</span>
                <b-spinner v-show="isLoading" class="position-absolute" small type="grow" />
              </b-button>
            </b-col>
          </b-row>
          <!-- Table Top -->

          <b-row>
            <b-col class="p-0 mb-1" cols="12" md="6" style="padding: 0 10px !important">
              <h6>عنوان برچسب</h6>
              <validation-provider #default="{ errors }" name="label" rules="required">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input v-model="formData.title" class="d-inline-block" placeholder="عنوان برچسب را وارد کنید"
                    :state="errors.length > 0 ? false : null" />
                </div>
                <small class="text-danger">{{
          errors[0] ? "این فیلد الزامی است" : ""
        }}</small>
              </validation-provider>
            </b-col>
            <b-col class="p-0 mb-1" cols="12" md="6" style="padding: 0 10px !important">
              <h6>اسلاگ</h6>
              <validation-provider #default="{ errors }" name="slug" rules="required">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input v-model="formData.slug" class="d-inline-block" placeholder="اسلاگ را انگلیسی وارد کنید"
                    :state="errors.length > 0 ? false : null" />
                </div>
                <small class="text-danger">{{
          errors[0] ? "این فیلد الزامی است" : ""
        }}</small>
              </validation-provider>
            </b-col>
            <b-col class="px-0 d-md-none" dir="ltr" cols="12" md="6" style="padding: 0 10px !important">
              <b-button class="d-flex justify-content-center align-items-center" variant="primary" type="submit"
                :disabled="invalid || isLoading" @click="createTag">
                <span class="text-nowrap">ثبت برچسب</span>
                <b-spinner v-show="isLoading" class="position-absolute" small type="grow" />
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </div>

      <!-- Table -->
      <LoadingComponent v-if="loading" />
      <b-col style="padding: 0 15px !important" v-else>
        <div v-if="tags.length > 0">
          <div class="pt-1 pb-1 overflow" v-if="tags.length > 0">
            <vue-good-table :sort-options="{ enabled: false }" :columns="columns" :rows="tags" :rtl="false"
              class="w-100">
              <div slot="emptystate" class="w-100 text-center">
                موردی یافت نشد
              </div>
              <template slot="table-row" slot-scope="props" class="mx-auto">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'id'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.id }}</span>
                </span>
                <span v-if="props.column.field === 'title'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.title }}</span>
                </span>
                <span v-if="props.column.field === 'slug'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.slug }}</span>
                </span>
                <span v-if="props.column.field === 'url'" class="text-nowrap">
                  <b-button @click="doCopy(props.row.title, props.row.id)" variant="primary"
                    :disabled="!props.row.title">کپی متن</b-button>
                </span>
                <span v-if="props.column.field === 'status'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.status }}</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'" style="width: 100px !important">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret :right="true">
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <!-- <b-dropdown-item @click="moveToEditAttribute(props.row.id)">
                                                <feather-icon icon="Edit2Icon" class="mr-50" />
                                                <span>ویرایش</span>
                                            </b-dropdown-item> -->
                      <b-dropdown-item @click="deletePrompt(props.row.id)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>حذف</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
            </vue-good-table>
          </div>
          <b-pagination v-model="currentPage" :total-rows="totalTags" :per-page="perPage" first-number last-number
            align="left" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0 p-0" dir="ltr">

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
        <div v-else>
          <h3 class="text-center">موردی یافت نشد</h3>
        </div>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BSpinner,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BPagination,
  BTable,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import axios from "axios";
import { VueGoodTable } from "vue-good-table";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LoadingComponent from "../../components/LoadingComponent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import copy from "copy-text-to-clipboard";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BAvatar,
    BCard,
    BSpinner,
    BFormInput,
    BRow,
    BTable,
    BCol,
    BButton,
    BPagination,
    BDropdownItem,
    BDropdown,
    ToastificationContent,
    LoadingComponent,
  },
  data() {
    return {
      required,
      isLoading: false,
      loading: false,
      tags: [],
      tagsLength: 0,
      tagsTray: [],
      totalTags: 0,
      perPage: 15,
      currentPage: 1,
      columns: [
        {
          label: "شناسه",
          field: "id",
        },
        {
          label: "عنوان",
          field: "title",
        },
        {
          label: "اسلاگ",
          field: "slug",
        },
        {
          label: "آدرس",
          field: "url",
        },
        {
          label: "عملیات",
          field: "action",
        },
      ],
      formData: {
        title: null,
        slug: null,
        status: 1,
      },
    };
  },
  methods: {
    getAllTags() {
      this.loading = true;
      this.tags = [];
      axios
        .get("/api/v1/admin/tags", { params: { page: this.currentPage } })
        .then((response) => {
          this.tagsLength = response.data.data.length;
          this.totalTags = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          if (this.tagsLength > 0) {
            this.tagsTray = response.data.data;
            this.tags = [];
            this.tagsTray.forEach((item) => {
              this.tags.push({
                id: item.id,
                title: item.title,
                slug: item.slug,
              });
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    deletePrompt(id) {
      this.$bvModal
        .msgBoxConfirm("آیا از حذف مطمئن هستید؟", {
          title: "",
          size: "sm",
          okVariant: "danger",
          okTitle: "بله",
          cancelTitle: "خیر",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deleteTag(id);
          }
        });
    },
    deleteTag(id) {
      axios
        .post(`/api/v1/admin/tags/${id}/delete`)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "برچسب حذف شد",
              icon: "CheckIcon",
              text: "برچسب با موفیقت حذف شد.",
              variant: "info",
            },
          });
          this.getAllTags();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    createTag() {
      this.isLoading = true;
      axios
        .post("/api/v1/admin/tags/create", this.formData)
        .then((response) => {
          if (response.status == 201) {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "برچسب ساخته شد",
                icon: "CheckIcon",
                text: "برچسب با موفقیت ساخته شد",
                variant: "success",
              },
            });
          }
          this.getAllTags();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    doCopy(text, id) {
      copy(
        `https://${process.env.VUE_APP_BASE_URL.split('https://api.')[1]}/tags/` + id + "/" + text.split(" ").join("-")
      );

      this.$toast({
        component: ToastificationContent,
        props: {
          title: `آدرس مورد نظر کپی شد`,
          icon: "XOctagonIcon",
          text:
            `https://${process.env.VUE_APP_BASE_URL.split('https://api.')[1]}/tags/` +
            id +
            "/" +
            text.split(" ").join("-"),
          variant: "success",
        },
      });
    },
  },
  mounted() {
    this.getAllTags();
    this.$watch("currentPage", (newCurrentPage) => {
      this.getAllTags();
    });
  },
  unmounted() {
    this.$watch("currentPage");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}
</style>